.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-detail {
  padding-bottom: 1rem;
  box-sizing: border-box;
}
.order-detail .order-top {
  width: 100%;
  height: 1rem;
  background: var(--main-color);
  padding: 0 0.3rem 0 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-detail .order-top .top-left {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  color: #fff;
}
.order-detail .order-top .top-left img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 6px;
}
.order-detail .order-top .top-right {
  color: #fff;
  font-size: 0.3rem;
}
.order-detail .order-address {
  height: 1.6rem;
  background: #fff;
  position: relative;
  padding: 0.36rem 0.3rem;
  box-sizing: border-box;
  line-height: 0.4rem;
}
.order-detail .order-address .user-info {
  font-size: 0.4rem;
  color: #333;
  margin-bottom: 3px;
}
.order-detail .order-address .user-info .user-name {
  margin-right: 0.26rem;
}
.order-detail .order-address .user-address {
  font-size: 0.22rem;
  color: #666666;
}
.order-detail .order-address .order-line {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.order-detail .material-box {
  padding: 0.3rem;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  color: #333333;
}
.order-detail .material-box .material-time {
  width: 0.86rem;
  margin-right: 0.6rem;
}
.order-detail .material-box .material-time p {
  text-align: center;
  white-space: nowrap;
}
.order-detail .material-box .material-time p:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.order-detail .material-box .material-time p:nth-child(2) {
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.order-detail .material-box .material-info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-detail .material-box .material-info img {
  width: 0.36rem;
  height: 0.36rem;
}
.order-detail .material-box .material-info .info p:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.order-detail .material-box .material-info .info p:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.34rem;
}
.order-detail .order-line {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.order-detail .order-line-img {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
}
.order-detail .order-content {
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
}
.order-detail .order-content .goods-shop {
  padding: 0.2rem;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  position: relative;
}
.order-detail .order-content .goods-shop::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  top: 50%;
  margin-top: -0.1rem;
  left: 0;
}
.order-detail .order-content .order-info {
  padding: 0 0.2rem 0.05rem 0.2rem;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .order-info .info-title {
  font-size: 0.3rem;
  color: #333;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.2rem;
}
.order-detail .order-content .order-info .info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
  margin-bottom: 0.2rem;
}
.order-detail .order-content .order-info .info-all {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 0.28rem;
  color: #333;
  height: 1rem;
  border-top: 1px solid #e1e1e1;
}
.order-detail .order-content .order-info .info-all p {
  margin-left: 5px;
}
.order-detail .order-content .order-info .order-line {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.order-detail .order-content .order-info .order-line:nth-last-child(1) {
  border: none;
}
.order-detail .order-content .after-reason {
  background: #fff;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
}
.order-detail .order-content .after-reason .after-title {
  font-size: 0.28rem;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .after-reason .reason-title {
  font-size: 0.28rem;
  color: #999999;
  margin-bottom: 0.2rem;
  line-height: 0.4rem;
}
.order-detail .order-content .after-reason .reason-imgs {
  display: flex;
  align-items: center;
}
.order-detail .order-content .after-reason .reason-imgs img {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
}
.order-detail .order-content .order-collect {
  width: 6.9rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  font-size: 0.28rem;
  color: #333333;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .order-collect .look {
  color: #999999;
  display: flex;
  align-items: center;
}
.order-detail .order-content .order-collect .look img {
  width: 0.24rem;
  height: 0.24rem;
  margin-left: 4px;
}
.order-detail .order-btn {
  width: 100%;
  height: 1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding-right: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.refuse-reason-wrap {
  width: calc(100% - 0.6rem);
  padding: 0.3rem 0.2rem 0.2rem;
  margin: 0.3rem auto 0;
  background-color: #fff;
}
.refuse-reason-wrap .title {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  letter-spacing: -0.68px;
  margin-bottom: 0.3rem;
}
.refuse-reason-wrap .refuse-reason {
  font-size: 0.28rem;
  color: #FF5252;
  line-height: 0.4rem;
  letter-spacing: -0.68px;
  word-wrap: break-word;
  word-break: break-all;
}
.fs-weight {
  font-weight: bold;
}
.color-main {
  color: var(--main-color);
}
