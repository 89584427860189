.border-none[data-v-e693661a]:before {
  display: none;
}
.border-none[data-v-e693661a]:after {
  display: none;
}
.flex-fix[data-v-e693661a] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-e693661a]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-e693661a] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-e693661a] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-e693661a] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-e693661a] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-e693661a] {
  *zoom: 1;
}
.clear-fix[data-v-e693661a]:before,
.clear-fix[data-v-e693661a]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.group-info[data-v-e693661a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.34rem 0.3rem 0.3rem;
  /*引导分享*/
}
.group-info .title[data-v-e693661a] {
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.group-info .title .num[data-v-e693661a] {
  margin: 0 0.08rem;
  color: var(--main-color);
  font-weight: bolder;
}
.group-info .group-list-wrap[data-v-e693661a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0 0.24rem 0.24rem 0.66rem;
  background-image: url('~@/assets/img_tuangou_bg_shixiao@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.group-info .group-list-wrap[data-v-e693661a] {
    background-image: url('~@/assets/img_tuangou_bg_shixiao@3x.png');
}
}
.group-info .group-list-wrap .group-list[data-v-e693661a] {
  position: relative;
  padding-top: 0.72rem;
  margin-bottom: 0.24rem;
}
.group-info .group-list-wrap .group-list li[data-v-e693661a] {
  float: left;
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  z-index: 0;
}
.group-info .group-list-wrap .group-list li img[data-v-e693661a] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.group-info .group-list-wrap .group-list li .head-badge[data-v-e693661a] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -0.1rem;
  display: inline-block;
  width: 0.56rem;
  height: 0.26rem;
  padding-top: 0.02rem;
  padding-left: 0.02rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.26rem;
  text-align: center;
  background-color: var(--main-color);
  border-radius: 0.13rem;
  overflow: hidden;
  z-index: 3;
}
.group-info .group-list-wrap .group-list li.active[data-v-e693661a] {
  border: 1px solid var(--main-color);
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.group-info .group-list-wrap .group-list li + li[data-v-e693661a] {
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.group-info .group-list-wrap .countdown-wrap[data-v-e693661a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0.4rem;
}
.group-info .group-list-wrap .countdown-wrap .gray-txt[data-v-e693661a] {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.32rem;
}
.group-info .group-list-wrap .countdown-wrap .time[data-v-e693661a] {
  margin: 0 0.08rem;
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
  font-weight: bold;
}
.group-info .group-list-wrap .group-btn[data-v-e693661a] {
  width: 2.4rem;
  height: 0.6rem;
  border-radius: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  background: var(--main-color);
  margin-top: 4px;
}
.group-info .guide-bg[data-v-e693661a] {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.group-info .guide-box[data-v-e693661a] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.group-info .guide-box .guide-top[data-v-e693661a] {
  width: 4.5rem;
  height: 3.7rem;
  background: url("../../assets/img_share@3x.png") no-repeat;
  background-size: 100%;
  margin-left: 2.58rem;
  margin-bottom: 2.58rem;
}
.group-info .guide-box .guide-btn[data-v-e693661a] {
  width: 2.6rem;
  height: 0.9rem;
}
