.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.group-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.34rem 0.3rem 0.3rem;
  /*引导分享*/
}
.group-info .title {
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.group-info .title .num {
  margin: 0 0.08rem;
  color: var(--main-color);
  font-weight: bolder;
}
.group-info .group-list-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0.24rem 0.24rem 0.66rem;
  background-image: url('~@/assets/img_tuangou_bg_shixiao@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .group-info .group-list-wrap {
    background-image: url('~@/assets/img_tuangou_bg_shixiao@3x.png');
  }
}
.group-info .group-list-wrap .group-list {
  position: relative;
  padding-top: 0.72rem;
  margin-bottom: 0.24rem;
}
.group-info .group-list-wrap .group-list li {
  float: left;
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  z-index: 0;
}
.group-info .group-list-wrap .group-list li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.group-info .group-list-wrap .group-list li .head-badge {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.1rem;
  display: inline-block;
  width: 0.56rem;
  height: 0.26rem;
  padding-top: 0.02rem;
  padding-left: 0.02rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.26rem;
  text-align: center;
  background-color: var(--main-color);
  border-radius: 0.13rem;
  overflow: hidden;
  z-index: 3;
}
.group-info .group-list-wrap .group-list li.active {
  border: 1px solid var(--main-color);
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.group-info .group-list-wrap .group-list li + li {
  margin-right: 0.4rem;
  margin-bottom: 0.32rem;
}
.group-info .group-list-wrap .countdown-wrap {
  display: flex;
  align-items: center;
  height: 0.4rem;
}
.group-info .group-list-wrap .countdown-wrap .gray-txt {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.32rem;
}
.group-info .group-list-wrap .countdown-wrap .time {
  margin: 0 0.08rem;
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
  font-weight: bold;
}
.group-info .group-list-wrap .group-btn {
  width: 2.4rem;
  height: 0.6rem;
  border-radius: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  background: var(--main-color);
  margin-top: 4px;
}
.group-info .guide-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.group-info .guide-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.group-info .guide-box .guide-top {
  width: 4.5rem;
  height: 3.7rem;
  background: url("../../assets/img_share@3x.png") no-repeat;
  background-size: 100%;
  margin-left: 2.58rem;
  margin-bottom: 2.58rem;
}
.group-info .guide-box .guide-btn {
  width: 2.6rem;
  height: 0.9rem;
}
