.border-none[data-v-bfdf2ec8]:before {
  display: none;
}
.border-none[data-v-bfdf2ec8]:after {
  display: none;
}
.flex-fix[data-v-bfdf2ec8] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-bfdf2ec8]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-bfdf2ec8] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-bfdf2ec8] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-bfdf2ec8] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-bfdf2ec8] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-bfdf2ec8] {
  *zoom: 1;
}
.clear-fix[data-v-bfdf2ec8]:before,
.clear-fix[data-v-bfdf2ec8]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-detail[data-v-bfdf2ec8] {
  padding-bottom: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.order-detail .order-top[data-v-bfdf2ec8] {
  width: 100%;
  height: 1rem;
  background: var(--main-color);
  padding: 0 0.3rem 0 0.24rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.order-detail .order-top .top-left[data-v-bfdf2ec8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.3rem;
  color: #fff;
}
.order-detail .order-top .top-left img[data-v-bfdf2ec8] {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 6px;
}
.order-detail .order-top .top-right[data-v-bfdf2ec8] {
  color: #fff;
  font-size: 0.3rem;
}
.order-detail .order-address[data-v-bfdf2ec8] {
  height: 1.6rem;
  background: #fff;
  position: relative;
  padding: 0.36rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 0.4rem;
}
.order-detail .order-address .user-info[data-v-bfdf2ec8] {
  font-size: 0.4rem;
  color: #333;
  margin-bottom: 3px;
}
.order-detail .order-address .user-info .user-name[data-v-bfdf2ec8] {
  margin-right: 0.26rem;
}
.order-detail .order-address .user-address[data-v-bfdf2ec8] {
  font-size: 0.22rem;
  color: #666666;
}
.order-detail .order-address .order-line[data-v-bfdf2ec8] {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.order-detail .material-box[data-v-bfdf2ec8] {
  padding: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #333333;
}
.order-detail .material-box .material-time[data-v-bfdf2ec8] {
  width: 0.86rem;
  margin-right: 0.6rem;
}
.order-detail .material-box .material-time p[data-v-bfdf2ec8] {
  text-align: center;
  white-space: nowrap;
}
.order-detail .material-box .material-time p[data-v-bfdf2ec8]:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.order-detail .material-box .material-time p[data-v-bfdf2ec8]:nth-child(2) {
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.order-detail .material-box .material-info[data-v-bfdf2ec8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.order-detail .material-box .material-info img[data-v-bfdf2ec8] {
  width: 0.36rem;
  height: 0.36rem;
}
.order-detail .material-box .material-info .info p[data-v-bfdf2ec8]:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.order-detail .material-box .material-info .info p[data-v-bfdf2ec8]:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.34rem;
}
.order-detail .order-line[data-v-bfdf2ec8] {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.order-detail .order-line-img[data-v-bfdf2ec8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.3rem 0;
}
.order-detail .order-content[data-v-bfdf2ec8] {
  padding: 0.2rem 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.order-detail .order-content .goods-shop[data-v-bfdf2ec8] {
  padding: 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  position: relative;
}
.order-detail .order-content .goods-shop[data-v-bfdf2ec8]::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  top: 50%;
  margin-top: -0.1rem;
  left: 0;
}
.order-detail .order-content .order-info[data-v-bfdf2ec8] {
  padding: 0 0.2rem 0.05rem 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .order-info .info-title[data-v-bfdf2ec8] {
  font-size: 0.3rem;
  color: #333;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.2rem;
}
.order-detail .order-content .order-info .info-item[data-v-bfdf2ec8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
  margin-bottom: 0.2rem;
}
.order-detail .order-content .order-info .info-all[data-v-bfdf2ec8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 0.28rem;
  color: #333;
  height: 1rem;
  border-top: 1px solid #e1e1e1;
}
.order-detail .order-content .order-info .info-all p[data-v-bfdf2ec8] {
  margin-left: 5px;
}
.order-detail .order-content .order-info .order-line[data-v-bfdf2ec8] {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.order-detail .order-content .order-info .order-line[data-v-bfdf2ec8]:nth-last-child(1) {
  border: none;
}
.order-detail .order-content .after-reason[data-v-bfdf2ec8] {
  background: #fff;
  padding: 0.3rem 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.order-detail .order-content .after-reason .after-title[data-v-bfdf2ec8] {
  font-size: 0.28rem;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .after-reason .reason-title[data-v-bfdf2ec8] {
  font-size: 0.28rem;
  color: #999999;
  margin-bottom: 0.2rem;
  line-height: 0.4rem;
}
.order-detail .order-content .after-reason .reason-imgs[data-v-bfdf2ec8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.order-detail .order-content .after-reason .reason-imgs img[data-v-bfdf2ec8] {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
}
.order-detail .order-content .order-collect[data-v-bfdf2ec8] {
  width: 6.9rem;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  font-size: 0.28rem;
  color: #333333;
  margin-bottom: 0.3rem;
}
.order-detail .order-content .order-collect .look[data-v-bfdf2ec8] {
  color: #999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.order-detail .order-content .order-collect .look img[data-v-bfdf2ec8] {
  width: 0.24rem;
  height: 0.24rem;
  margin-left: 4px;
}
.order-detail .order-btn[data-v-bfdf2ec8] {
  width: 100%;
  height: 1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding-right: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.refuse-reason-wrap[data-v-bfdf2ec8] {
  width: calc(100% - 0.6rem);
  padding: 0.3rem 0.2rem 0.2rem;
  margin: 0.3rem auto 0;
  background-color: #fff;
}
.refuse-reason-wrap .title[data-v-bfdf2ec8] {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  letter-spacing: -0.68px;
  margin-bottom: 0.3rem;
}
.refuse-reason-wrap .refuse-reason[data-v-bfdf2ec8] {
  font-size: 0.28rem;
  color: #FF5252;
  line-height: 0.4rem;
  letter-spacing: -0.68px;
  word-wrap: break-word;
  word-break: break-all;
}
.fs-weight[data-v-bfdf2ec8] {
  font-weight: bold;
}
.color-main[data-v-bfdf2ec8] {
  color: var(--main-color);
}
